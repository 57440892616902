import * as VueI18n from 'vue-i18n'
import localforage from 'localforage'

const DEFAULT_LANGUAGE = 'en'

export const availableLanguages = [
  {
    caption: 'Português (Brasil)',
    value: 'pt-br',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/br.png",
    dd1: "55"
  },
  {
    caption: 'Português (Portugal)',
    value: 'pt-pt',
    context: ['content'],
    flag: "https://flagcdn.com/48x36/pt.png",
    ddi: "351"
  },
  {
    caption: 'Español',
    value: 'es',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/es.png",
    ddi: "34"
  },
  {
    caption: 'English',
    value: 'en',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/us.png",
    ddi: "1"
  },
  // {
  //   caption: 'United Kingdom',
  //   value: 'gb',
  //   context: ['app', 'content'],
  //   flag: "https://flagcdn.com/48x36/gb.png",
  //   ddi: "44"
  // },
  {
    caption: 'Français',
    value: 'fr',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/fr.png",
    ddi: "33"
  },
  {
    caption: 'Italiano',
    value: 'it',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/it.png",
    ddi: "39"
  },
  {
    caption: 'Deutsch',
    value: 'de',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/de.png",
    ddi: "49"
  },
  {
    caption: '한국어 (Korean)',
    value: 'ko',
    context: ['app', 'content'],
    flag: "https://flagcdn.com/48x36/kr.png",
    ddi: "82"
  },
  {
    caption: '日本 (Japan)',
    value: 'jp',
    context: ['content'],
    flag: "https://flagcdn.com/48x36/jp.png",
    ddi: "81"
  }
]

const getBrowserLanguage = () => {
  if (navigator && navigator.language) {
    const languages = availableLanguages.map( (language) => language.value)
    const browserLanguage = navigator.language.toLocaleLowerCase()
    if (languages.includes(browserLanguage)) {
      return browserLanguage
    }
  }
  return DEFAULT_LANGUAGE
}

const getSelectedLanguage = async () => {
  const lastSelectedLanguage = await localforage.getItem('language') as string
  return lastSelectedLanguage || getBrowserLanguage()
}

const getMessages = async () => {
  const languages = availableLanguages
    .filter((language) => language.context.includes('app'))  
    .map( (language) => language.value)

  const messages = await languages.reduce( async (previous, current) => {
    return {
      ...await previous,
      [current]: await import(`@/assets/i18next/${current}.json`)
    }
  }, Promise.resolve({}))

  return messages
}

export const createI18n = async () => {
  const selectedLanguage = await getSelectedLanguage()
  const messages = await getMessages()

  const i18n = VueI18n.createI18n({
    locale: selectedLanguage,
    fallbackLocale: DEFAULT_LANGUAGE,
    warnHtmlInMessage: 'off',
    messages
  })

  return {
    i18n
  }
}
