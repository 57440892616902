import { firestore } from '@/plugins/firebase'
import { useAxios } from '@/composables/useAxios'
import localforage from 'localforage'

const getDefaultState = () => {
  return {
    currentProfile: null,
    analytics: {
      facebook: {},
      instagram: {},
      linkedin: {},
      twitter: {}
    },
    isEmpty: true,
    isLoaded: false,
    competitors: {
      facebook: {},
      instagram: {},
      linkedin: {},
      twitter: {}
    },
    isEmptyCompetitors: true,
    isLoadedCompetitors: false,
    requestServerActive: false
  }
}

const state = getDefaultState()

const getters = {
  analytics: ({ analytics }: any) => {
    return analytics
  },
  isEmpty: ({ isEmpty }: any) => {
    return isEmpty
  },
  isLoaded: ({ isLoaded }: any) => {
    return isLoaded
  },
  competitors: ({ competitors }: any) => {
    return competitors
  },
  isEmptyCompetitors: ({ isEmptyCompetitors }: any) => {
    return isEmptyCompetitors
  },
  isLoadedCompetitors: ({ isLoadedCompetitors }: any) => {
    return isLoadedCompetitors
  },
}

const mutations = {
  setAnalytics (state: any, payload: Object) {
    state.analytics = payload
  },
  setIsEmpty (state: any, payload: boolean) {
    state.isEmpty = payload
  },
  setIsLoaded (state: any, payload: boolean) {
    state.isLoaded = payload
  },
  setCompetitors (state: any, payload: Object) {
    state.competitors = payload
  },
  setIsEmptyCompetitors (state: any, payload: boolean) {
    state.isEmptyCompetitors = payload
  },
  setIsLoadedCompetitors (state: any, payload: boolean) {
    state.isLoadedCompetitors = payload
  },
  setCurrentProfile  (state: any, payload: boolean) {
    state.currentProfile = payload
  },
  clearStore (state: any) {
    state = getDefaultState()
  },
}

const actions = {
  async requestServerUpdateData ({state}: any, profileId: string) {
    const { httpFunctions } = await useAxios()
    
    const cacheKey = `last-analytics-call-${profileId}`
    const lastCall = await localforage.getItem(cacheKey) as any
    
    let makeNewRequest = true
    
    if (lastCall) {
      const date = new Date(lastCall)
      makeNewRequest = date.setMinutes(date.getMinutes() + 30) < new Date().getTime()
    }

    if (makeNewRequest && !state.requestServerActive) {
      try {
        state.requestServerActive = true
        // await httpFunctions.get(`/manySocial-scan/${profileId}`)
        await httpFunctions.post(`/analytics-requests/trigger/analytics/${profileId}`)
        await localforage.setItem(cacheKey, new Date().getTime())
      } catch (error) {
        console.log(error)
      }
      state.requestServerActive = false
    }
  },
  async fetchAnalytics ({ state, commit, dispatch, rootGetters }: any, makeRequest: boolean = false) {
    const profileId = rootGetters['profiles/current'].id

    if (makeRequest) {
      dispatch('requestServerUpdateData', profileId)
    }

    if (!state.isLoaded || state.currentProfile !== profileId) {
      commit('setCurrentProfile', profileId)
      firestore
        .collection('analytics2')
        .where('profileId', '==', profileId)
        .onSnapshot(async snap => {
          commit('setIsEmpty', snap.empty)
          const analyticsData = {
            facebook: {},
            instagram: {},
            linkedin: {},
            twitter: {},
          };
          if (!snap.empty) {
            snap.docs.map((doc) => {
              const docData = doc.data() || {}
              const source = docData.source as 'facebook' | 'instagram' | 'linkedin' | 'twitter'
      
              analyticsData[source] = docData
            })
          }
          commit('setAnalytics', analyticsData)
          commit('setIsLoaded', true)
        })
    }
  },
  async fetchCompetitors ({ state, commit, dispatch, rootGetters }: any, makeRequest: boolean = false) {
    const profileId = rootGetters['profiles/current'].id

    if (makeRequest) {
      dispatch('requestServerUpdateData', profileId)
    }

    if (!state.isLoadedCompetitors || state.currentProfile !== profileId) {
      commit('setCurrentProfile', profileId)
      firestore
        .collection('competitorsAnalytics')
        .where('profileId', 'array-contains', profileId)
        .onSnapshot(snap => {
          commit('setIsEmptyCompetitors', snap.empty)
          const analyticsData: Record<'facebook' | 'instagram' | 'linkedin' | 'twitter', any[]> = {
            facebook: [],
            instagram: [],
            linkedin: [],
            twitter: [],
          };
          if (!snap.empty) {
            snap.docs.map((doc) => {
              const docData = doc.data() || {}
              const source = docData.source as 'facebook' | 'instagram' | 'linkedin' | 'twitter'

              analyticsData[source].push(docData)
            })
          }
          commit('setCompetitors', analyticsData)
          commit('setIsLoadedCompetitors', true)
        })
    }
  }
}

export const analytics = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
