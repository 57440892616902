import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth(firebaseApp)
export const firestore = firebase.firestore(firebaseApp)
export const storage = firebase.storage(firebaseApp)
export const admin = firebase
export const functionsEndpoint = process.env.VUE_APP_ENDPOINT

// if (window.location.hostname === 'localhost') {
//   auth.useEmulator('http://localhost:9099/')

//   firestore.useEmulator('localhost', 8081)
// }

export { firebase }
