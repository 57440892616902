import { useAxios } from "@/composables/useAxios"
import { ContentPublisherGatewayInterface, CreateScheduleInputInterface, ScheduleInterface } from "./ContentPublisherGatewayInterface"

const END_POINT = process.env.VUE_APP_CONTENT_PUBLICATION_ENDPOINT

export class ContentPublisherGateway implements ContentPublisherGatewayInterface {

  async create(profileId: string, props: CreateScheduleInputInterface): Promise<void> {
    const { httpFunctions } = await useAxios()
    const {contentId, sources, scheduledTo} = props

    await httpFunctions.post(`/contentPublisher/${profileId}`, {
      contentId,
      sources,
      ...(scheduledTo && {scheduledTo})
    })
  }

  async list(profileId: string): Promise<ScheduleInterface[]> {
    const { httpFunctions } = await useAxios({config: {baseURL: END_POINT}})

    const { data: response } = await httpFunctions.get(`/customer/${profileId}/publication`)

    const result: ScheduleInterface[] = response.map((schedule: any) => {
      return {
        id: schedule.id,
        content: {
          contentId: schedule.external_id,
          title: schedule.content.title,
          content: schedule.content.content,
          hashtags: schedule.content.hashtags,
          media: schedule.content.media,
          thumbnail: schedule.content.thumbnail,
          videoCover: schedule.content.video_cover
        },
        source: schedule.social_account.source,
        publishedAt: new Date(schedule.published_at),
        _isProcessed: schedule._isProcessed,
        _isPublished: schedule._isPublished,
        _errorMessage: schedule._errorMessage,
      }
    })

    return Promise.resolve(result)
  }

  async get(profileId: string, contentId: string): Promise<ScheduleInterface> {
    const { httpFunctions } = await useAxios()

    const { data: schedule } = await httpFunctions.get<ScheduleInterface>(`${END_POINT}/${profileId}/${contentId}`)

    return Promise.resolve(schedule)
  }

  async delete(profileId: string, scheduleId: string): Promise<void> {
    const { httpFunctions } = await useAxios()

    await httpFunctions.delete(`/contentPublisher/${profileId}/${scheduleId}`)

    return Promise.resolve()
  }
}