export default [
  {
    code: "BR",
    value: "55",
    masks: ["(##) ####-####", "(##) #####-####"],
    name: "Brazil",
    flag: "https://flagcdn.com/48x36/br.png",
  },
  {
    code: "US",
    value: "1",
    masks: "",
    name: "United States",
    flag: "https://flagcdn.com/48x36/us.png",
  },
  {
    code: "PT",
    value: "351",
    masks: "",
    name: "Portugal",
    flag: "https://flagcdn.com/48x36/pt.png",
  },
  {
    code: "GB",
    value: "44",
    masks: "",
    name: "United Kingdom",
    flag: "https://flagcdn.com/48x36/gb.png",
  },
  {
    code: "ES",
    value: "34",
    masks: "",
    name: "Spain",
    flag: "https://flagcdn.com/48x36/es.png",
  },
  {
    code: "FR",
    value: "33",
    masks: "",
    name: "France",
    flag: "https://flagcdn.com/48x36/fr.png",
  },
  {
    code: "DE",
    value: "49",
    masks: "",
    name: "Germany",
    flag: "https://flagcdn.com/48x36/de.png",
  },
  {
    code: "IT",
    value: "39",
    masks: "",
    name: "Italy",
    flag: "https://flagcdn.com/48x36/it.png",
  },
  {
    code: "KR",
    value: "82",
    masks: "",
    name: "South Korea",
    flag: "https://flagcdn.com/48x36/kr.png",
  },
  {
    code: "CA",
    value: "1",
    masks: "",
    name: "Canada",
    flag: "https://flagcdn.com/48x36/ca.png",
  },
  {
    code: "JP",
    value: "81",
    masks: "",
    name: "Japan",
    flag: "https://flagcdn.com/48x36/jp.png"
  }
]