import { auth, firestore } from '@/plugins/firebase'

const getCurrentProfileFromLocalstorage = (profile: string|null = null) => {
  const profileCached = window.localStorage.getItem('profile')

  if (profileCached) {
    return profileCached
  }

  return profile
}

const getFirstActiveProfileIndex = (profiles: any[]) => {
  const firstActiveProfileIndex = profiles.findIndex((profile: any) => profile.plan?.active)
  return firstActiveProfileIndex === -1 ? 0 : firstActiveProfileIndex
}

const profilesState = {
  isLoaded: false,
  profiles: [],
  current: getCurrentProfileFromLocalstorage()
}

const getters = {
  current: ({ current, profiles }: any) => {
    return [...profiles].find((profile: any) => profile.id === current)
  },
  allExceptCurrent: ({ current, profiles }: any) => {
    return [...profiles].filter((profile: any) => profile.id !== current)
  },
  all: ({ profiles }: any) => {
    return [...profiles]
  },
}

const mutations = {
  setIsLoaded (state: any, payload: boolean) {
    state.isLoaded = payload
  },
  setProfiles (state: any, payload: []) {
    state.profiles = payload
  },
  setCurrent (state: any, currentProfileId: string) {
    window.localStorage.setItem('profile', currentProfileId)
    state.current = currentProfileId
  },
  addProfile (state: any, payload: any) {
    state.profiles.push(payload)
  },
}

const actions = {
  async fetch ({ state, commit, dispatch }: any) {
    if (state.isLoaded === false) {
      const currentUser = auth.currentUser

      if (currentUser) {
        const profilesCollection = firestore.collection('profiles')

        profilesCollection
          .where('user', '==', currentUser.uid)
          .orderBy('createdAt', 'desc')
          .onSnapshot(profileSnapshot => {
            const profiles = profileSnapshot.docs?.map((profileSnap) => {
              return {
                ...profileSnap.data(),
                id: profileSnap.id
              }
            })
    
            const firstActiveProfileIndex = getFirstActiveProfileIndex(profiles)
    
            const currentProfileId = getCurrentProfileFromLocalstorage(profiles[firstActiveProfileIndex].id)
            commit('setProfiles', profiles)
            commit('setCurrent', currentProfileId)
            dispatch('notifications/fetch', currentProfileId, {root:true})
    
            commit('setIsLoaded', true)
          })

          // eslint-disable-next-line no-constant-condition
          while(true) {
            if (state.isLoaded) break
            await new Promise(resolve => setTimeout(resolve, 500))
          }
      }
    }
  },
  async updateCurrent ({ state }: any, newProfileData: any = {}) {
    const { current: id, profiles } = state

    const index = [...profiles].findIndex((profile) => profile.id === id)

    const currentData = profiles[index]

    const newData = {
      ...currentData,
      ...newProfileData
    }

    profiles[index] = newData

    await firestore
      .collection('profiles')
      .doc(id)
      .set({ ...newProfileData }, { merge: true })

    return id
  }
}

export const profiles = {
  namespaced: true,
  state: profilesState,
  getters,
  mutations,
  actions,
}
