
export interface Person {
  id: string;
  email: string | null;
  name: string | null;
  createdAt?: string | undefined;
  properties?: {
    plan: string;
  };
}

export const nps = () => {
  const initNps = async (params: Person) => {
    // @ts-ignore
    delighted.survey({
      ...params,
      minTimeOnPage: 10
    })
  }

  return {
    initNps
  }
}