import { auth, firestore } from '@/plugins/firebase'

const state = {
  isLoaded: false,
  contentPacks: []
}

const getters = {
  currentHeadlinePack: ({contentPacks}: {contentPacks: any[]}) => {
    const today = new Date()
    return contentPacks.find((contentPack) => {
      return contentPack.contentType === 'tokens' &&
      contentPack.startsAt <= today &&
      contentPack.endsAt >= today
    })
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setContentPacks (state: any, payload: any[]) {
    state.contentPacks = payload
  }
}

const actions = {
  async fetch ({ state, commit }: any, profileId: string) {
    if (state.isLoaded === false) {
      const currentUser = auth.currentUser
  
      if (currentUser) {
        firestore
          .collection('contentPack')
          .where('profileId', '==', profileId)
          .where('user', '==', currentUser.uid)
          .onSnapshot(querySnapshot => {
            const contentPacks = querySnapshot.docs
              .map(contentPack => ({
                id: contentPack.id,
                ...contentPack.data(),
                startsAt: contentPack.data().startsAt.toDate(),
                endsAt: contentPack.data().endsAt.toDate(),
                createdAt: contentPack.data().createdAt.toDate(),
                updatedAt: contentPack.data().updatedAt.toDate()
              }))
    
            commit('setContentPacks', contentPacks)
            commit('setLoaded')
          })
      }
    }
  }
}

export const contentPack = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}