import crelloService from '@/services/crelloService'

const state = {
  isLoaded: false,
  isLoading: false,
  count: 0,
  templates: []
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setIsLoading (state: any, isLoading: boolean) {
    state.isLoading = isLoading
  },
  setCount (state: any, count: number) {
    state.count = count
  },
  setTemplates (state: any, payload: any[]) {
    state.templates = payload
  }
}

const actions = {
  async fetch ({ state, commit }: any, filters: {}) {
    commit('setIsLoading', true)
    const {templates, count} = (await crelloService.fetchTemplates(filters))

    const result = templates.map((template) => {
      return {
        id: template.id,
        thumbnail: template.thumbnail
      }
    })

    commit('setCount', count)
    commit('setLoaded')
    commit('setIsLoading', false)
    commit('setTemplates', [...state.templates, ...result])
  }
}

export const templates = {
  namespaced: true,
  state,
  mutations,
  actions,
}