import { useAxios } from '@/composables/useAxios'
import { auth, firestore } from '@/plugins/firebase'

const state = {
  isLoaded: false,
  isGenerating: false,
  lastGenerationRequest: null,
  ideas: []
}

const getters = {
  newIdeas: ({ ideas, lastGenerationRequest }: {ideas: any[], lastGenerationRequest: Date | null}) => {
    if (!lastGenerationRequest) {
      return []
    }
    return ideas.filter(idea => (idea.createdAt >= lastGenerationRequest && !idea.approved))
  },
  available: ({ideas}: {ideas: any[]}) => {
    return ideas.filter(idea => !idea.archived && !idea.used)
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setIdeas (state: any, payload: any[]) {
    state.ideas = payload
  },
  setIsGenerating (state: any, payload: boolean) {
    state.isGenerating = payload
    if (payload) {
      state.lastGenerationRequest = new Date()
    }
  }
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    firestore
      .collection('headline')
      .where('lockedFor', '==', profileId)
      .where('requesterRoler', '==', 'customer')
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const ideas = querySnapshot.docs
          .map(idea => ({
            id: idea.id,
            ...idea.data(),
            approved: idea.data().approved || false,
            createdAt: idea.data().createdAt.toDate()
          }))
          .filter(idea => !('rejectedBy' in idea))
        commit('setIsGenerating', false)
        commit('setIdeas', ideas)
        commit('setLoaded')
      })
  },
  async delete(_context: any, ideaId: string) {
    const currentUser = auth.currentUser

    if (currentUser) {
      await firestore
        .collection('headline')
        .doc(ideaId)
        .update({
          archived: true,
          updatedAt: new Date()
        })
    }
  },
  async approve(_context: any, ideaId: string) {
    await firestore
      .collection('headline')
      .doc(ideaId)
      .update({
        approved: true,
        updatedAt: new Date()
      })
  },
  async makeUsed(_context: any, ideaId: string) {
    await firestore
      .collection('headline')
      .doc(ideaId)
      .update({
        used: true,
        updatedAt: new Date()
      })
  },
  async generate({ commit }: any, { keywords, profileId } : {keywords: string[], profileId: string}) {
    commit('setIsGenerating', true)
    const { httpFunctions } = await useAxios()
    await httpFunctions.post('/contentGeneration-requests/headline', {
      profileId,
      keywords,
      amount: 3
    })
  }
}

export const ideas = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}