import { RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import LoginCallback from '../views/LoginCallback.vue'
import Logout from '../views/Logout.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'../views/Home.vue'),
    meta: {
      title: `Manycontent`,
      breadcrumb: []
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `Manycontent - login`
    }
  },
  {
    path: '/login/callback',
    name: 'login-callback',
    component: LoginCallback,
    meta: {
      title: `Manycontent - login`
    }
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import(/* webpackChunkName: "auth" */'../views/Recovery.vue'),
    meta: {
      title: `Manycontent - Password recovery`
    }
  },
  {
    path: '/register',
    name: 'register',
    redirect: { name: 'login' },
    // component: () => import(/* webpackChunkName: "register" */'../views/Register.vue'),
    meta: {
      title: `Manycontent - Register`
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: `Manycontent - Logout`
    }
  },
  {
    path: '/pages/thanks',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "tanks" */'../views/pages/ThanksPage.vue'),
    meta: {
      title: `Manycontent - Tanks`,
      useMautic: true,
    }
  },
  {
    path: '/pages/checkout/:checkout_code',
    name: 'checkoutPage',
    component: () => import(/* webpackChunkName: "checkoutPage" */'../views/pages/CheckoutPage.vue'),
    meta: {
      title: `Manycontent - checkout`,
      useMautic: true,
      useLuckyOrange: true,
    }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "analytics-and-report" */'../views/Analytics.vue'),
    meta: {
      title: `Manycontent - Analytics`,
      breadcrumb: [
        { name: 'nav.sidebar.analytics', icon: 'fas fa-chart-line' }
      ]
    }
  },
  {
    path: '/competitors',
    name: 'competitors',
    component: () => import(/* webpackChunkName: "competitors-and-report" */'../views/Competitors.vue'),
    meta: {
      title: `Manycontent - Competitors`,
      breadcrumb: [
        { name: 'nav.sidebar.competitors', icon: 'fas fa-project-diagram' }
      ]
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "schedule-posts" */'../views/Schedule.vue'),
    meta: {
      title: `Manycontent - Schedule`,
      breadcrumb: [
        { name: 'nav.sidebar.schedule', icon: 'fas fa-chart-line' }
      ]
    }
  },
  {
    path: '/contents',
    name: 'contents',
    component: () => import(/* webpackChunkName: "contents" */'../views/posts/Posts.vue'),
    meta: {
      title: `Manycontent - Create Content`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks' }
      ]
    }
  },
  {
    path: '/posts',
    name: 'my-posts',
    component: () => import(/* webpackChunkName: "my-posts" */'../views/posts/MyPosts.vue'),
    meta: {
      title: `Manycontent - My Posts`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'nav.sidebar.my_contents', icon: 'far fa-folder-open' }
      ]
    }
  },
  {
    path: '/posts/:id',
    name: 'edit-post',
    component: () => import(/* webpackChunkName: "my-posts" */'../views/posts/Post.vue'),
    meta: {
      title: `Manycontent - Edit Post`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'nav.sidebar.my_contents', icon: 'fas fa-inbox', link: 'my-posts' },
        { name: 'nav.breadcrumb.editing_content' }
      ]
    }
  },
  {
    path: '/posts/create',
    name: 'create-post',
    component: () => import(/* webpackChunkName: "my-posts" */'../views/posts/Post.vue'),
    meta: {
      title: `Manycontent - Create Post`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'nav.sidebar.my_contents', icon: 'fas fa-inbox', link: 'my-posts' },
        { name: 'nav.breadcrumb.new_content' }
      ]
    }
  },
  {
    path: '/review-posts',
    name: 'review-posts',
    component: () => import(/* webpackChunkName: "review-posts" */'../views/posts/ReviewPosts.vue'),
    meta: {
      title: `Manycontent - Review Post`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.posts.review_title', icon: 'fas fa-check' }
      ]
    }
  },
  {
    path: '/ready-to-use-posts',
    name: 'ready-to-use-posts',
    component: () => import(/* webpackChunkName: "ready-to-use-posts" */'../views/posts/PostsReadyToUse.vue'),
    meta: {
      title: `Manycontent - Free Posts`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.posts.post_read_to_use_title', icon: 'fas fa-gift' }
      ]
    }
  },
  {
    path: '/ideas',
    name: 'my-ideas',
    component: () => import(/* webpackChunkName: "my-posts" */'../views/posts/MyIdeas.vue'),
    meta: {
      title: `Manycontent - My Ideas`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.posts.my_ideas_title', icon: 'fas fa-rocket' }
      ]
    }
  },
  {
    path: '/idea-generator',
    name: 'idea-generator',
    component: () => import(/* webpackChunkName: "idea-generator" */'../views/posts/IdeaGenerator.vue'),
    meta: {
      title: `Manycontent - Idea generator`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.posts.idea_generator_title', icon: 'far fa-lightbulb' }
      ]
    }
  },
  {
    path: '/content-generator',
    name: 'content-generator',
    component: () => import(/* webpackChunkName: "content-generator" */'../views/posts/ContentGenerator.vue'),
    meta: {
      title: `Manycontent - Content generator`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.posts.content_generator_title', icon: 'fas fa-robot' }
      ]
    }
  },
  {
    path: '/whatsapp-link',
    name: 'tools-whatsapp-link',
    component: () => import(/* webpackChunkName: "tools" */'../views/tools/WhatsappLink.vue'),
    meta: {
      title: `Manycontent - Whatsapp Link`,
      breadcrumb: [
        { name: 'nav.sidebar.contents', icon: 'fas fa-tasks', link: 'contents' },
        { name: 'page.tools.whatsapp_link.title' }
      ]
    }
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: () => import(/* webpackChunkName: "wizard" */'../views/Wizard.vue'),
    meta: {
      title: `Manycontent - Wizard`,
    }
  },
  {
    path: '/change-profile',
    name: 'change-profile',
    component: () => import('../views/ChangeProfile.vue'),
    meta: {
      title: `Manycontent - Change Profile`,
    }
  },
  {
    path: '/example-modal',
    name: 'example-modal',
    component: () => import('../views/ExampleModal.vue')
  },
  {
    path: '/cancel/survey',
    name: 'unsubscribe-survey',
    component: () => import(/* webpackChunkName: "unsubscribe" */'../views/CancellationSurvey.vue'),
    meta: {
      title: `Manycontent - Cancellation Survey`,
      breadcrumb: [
        { name: 'nav.sidebar.settings' },
        { name: 'nav.breadcrumb.my_account', link: 'settings-account' },
        { name: 'nav.breadcrumb.request_cancellation' }
      ]
    }
  },
  {
    path: '/cancel/list',
    name: 'unsubscribe-list',
    component: () => import(/* webpackChunkName: "unsubscribe" */'../views/CancellationsList.vue'),
    meta: {
      title: `Manycontent - Cancellation List`,
      breadcrumb: [
        { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
        { name: 'nav.breadcrumb.my_account', link: 'settings-account' },
        { name: 'nav.breadcrumb.cancellation_requests' }
      ]
    }
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import(/* webpackChunkName: "tools" */'../views/tools/Tools.vue'),
    meta: {
      title: `Manycontent - Tools`,
      breadcrumb: [
        { name: 'nav.sidebar.tools', icon: 'fas fa-tools' }
      ]
    },
  },
  {
    path: '/tool',
    name: 'tool',
    component: () => import(/* webpackChunkName: "tools" */'../views/tools/Tool.vue'),
    meta: {
      title: `Manycontent - Tools`,
      breadcrumb: [
        { name: 'nav.sidebar.tools', icon: 'fas fa-tools' }
      ]
    },
    children: [
      {
        path: 'title-generator',
        name: 'tools-title-generator',
        component: () => import(/* webpackChunkName: "tools" */'../views/tools/TitleGenerator.vue'),
        meta: {
          title: `Manycontent - Title Generator`,
          breadcrumb: [
            { name: 'nav.sidebar.tools', icon: 'fas fa-tools', link: 'tools' },
            { name: 'page.tools.title-generator.title' }
          ]
        }
      },
      {
        path: 'persona-generator',
        name: 'tools-persona-generator',
        component: () => import(/* webpackChunkName: "tools" */'../views/tools/PersonaGenerator.vue'),
        meta: {
          title: `Manycontent - Persona Generator`,
          breadcrumb: [
            { name: 'nav.sidebar.tools', icon: 'fas fa-tools', link: 'tools' },
            { name: 'page.tools.persona_generator.title' }
          ]
        }
      }
    ]
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */'../views/Settings.vue'),
    children: [
      {
        path: 'account',
        name: 'settings-account',
        component: () => import(/* webpackChunkName: "settings" */'../views/settings/SettingsAccount.vue'),
        meta: {
          title: `Manycontent - Account Settings`,
          breadcrumb: [
            { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
            { name: 'nav.breadcrumb.my_account' }
          ]
        }
      },
      {
        path: 'profile',
        name: 'settings-profile',
        component: () => import(/* webpackChunkName: "settings" */'../views/settings/SettingsProfile.vue'),
        meta: {
          title: `Manycontent - Profile Settings`,
          breadcrumb: [
            { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
            { name: 'nav.breadcrumb.my_profile' }
          ]
        }
      },
      {
        path: 'social-medias',
        name: 'settings-social-medias',
        component: () => import(/* webpackChunkName: "settings" */'../views/settings/SettingsSocialMedias.vue'),
        meta: {
          title: `Manycontent - Social Media Settings`,
          breadcrumb: [
            { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
            { name: 'nav.breadcrumb.social_accounts' }
          ]
        }
      },
      {
        path: 'preferences',
        name: 'settings-preferences',
        component: () => import(/* webpackChunkName: "settings" */'../views/settings/SettingsPreferences.vue'),
        meta: {
          title: `Manycontent - Preferences Settings`,
          breadcrumb: [
            { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
            { name: 'nav.breadcrumb.preferences' }
          ]
        }
      }
    ]
  },
  {
    path: '/settings/profile/templates',
    name: 'settings-templates',
    component: () => import(/* webpackChunkName: "templates" */'../views/settings/SettingsTemplates.vue'),
    meta: {
      title: `Manycontent - Templates`,
      breadcrumb: [
        { name: 'nav.sidebar.settings', icon: 'fas fa-cog' },
        { name: 'nav.breadcrumb.my_profile', link: 'settings-profile' },
        { name: 'page.settings.templates.title' }
      ]
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: () => import(/* webpackChunkName: "plans" */'../views/Plans.vue'),
    meta: {
      title: `Manycontent - Checkout Settings`,
      breadcrumb: [
        { name: 'nav.breadcrumb.subscription' }
      ]
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import(/* webpackChunkName: "courses" */'../views/courses/Courses.vue'),
    meta: {
      title: `Manycontent - Courses`,
      breadcrumb: [
        { name: 'nav.sidebar.courses', icon: 'fas fa-graduation-cap', link: 'courses' }
      ]
    }
  },
  {
    path: '/course/:courseId',
    name: 'course',
    component: () => import(/* webpackChunkName: "courses" */'../views/courses/Course.vue'),
    meta: {
      title: `Manycontent - Courses`,
      breadcrumb: [
        { name: 'nav.sidebar.courses', icon: 'fas fa-graduation-cap', link: 'courses' }
      ]
    }
  },
  {
    path: '/manybot',
    name: 'chat-manybot',
    component: () => import(/* webpackChunkName: "chat" */'../views/Chat.vue'),
    meta: {
      title: `Manycontent - Chat`,
      breadcrumb: [
        { name: 'nav.sidebar.chatbot', icon: 'far fa-comment-alt' }
      ]
    }
  }
]
