export function deepMergeObject(targetObject = {}, sourceObject = {}) {
  // clone the source and target objects to avoid the mutation
  const copyTargetObject = JSON.parse(JSON.stringify(targetObject));
  const copySourceObject = JSON.parse(JSON.stringify(sourceObject));
  // Iterating through all the keys of source object
  Object.keys(copySourceObject).forEach((key) => {
    if (typeof copySourceObject[key] === "object" && !Array.isArray(copySourceObject[key])) {
      // If property has nested object, call the function recursively
      copyTargetObject[key] = deepMergeObject(
        copyTargetObject[key],
        copySourceObject[key]
      );
    } else {
      // else merge the object source to target
      copyTargetObject[key] = copySourceObject[key];
    }
  });

  return copyTargetObject;
}