import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth, firebase } from '@/plugins/firebase'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import localforage from 'localforage'
interface LoginError extends Error {
  code?: string
}

// Configuração para ambiente de desenvolvimento (localhost)
const devActionCodeSettings = {
  url: 'http://localhost:8080/login/callback', // Ajuste a porta conforme sua configuração
  handleCodeInApp: true,
};

// Configuração para ambiente de produção
const prodActionCodeSettings = {
  url: 'https://app.manycontent.com/login/callback',
  handleCodeInApp: true,
};

class FirebaseAuthError extends Error {
  code: string;

  constructor(code: string, message: string) {
    super(message);
    this.name = "FirebaseError";
    this.code = code;
  }
}

// Função que retorna a configuração adequada baseada no ambiente
const getActionCodeSettings = () => {
  const isDevelopment = window.location.hostname === 'localhost';
  return isDevelopment ? devActionCodeSettings : prodActionCodeSettings;
};

export const useAuth = () => {
  const router = useRouter()

  const { t } = useI18n()

  const isLoggedIn = ref(auth.currentUser !== null)

  auth.onAuthStateChanged((user) => {
    isLoggedIn.value = user !== null
  })

  const logout = async () => {
    try {
      await localforage.clear()
      window.localStorage.clear()
      await auth.signOut()
      window.location.href = '/login'
    } catch (error) {
      // nothing
    }
  }

  const redirectAfterLogin = () => {
    router.replace({ name: 'change-profile' })
  }

  const alertOnError = (error: LoginError) => {
    const text = () => {
      const errorMessages = {
        'auth/invalid-email': t('sentence.invalid_email'),
        'auth/wrong-password': t('sentence.wrong-password'),
        'auth/user-not-found': t('sentence.user-not-found'),
        'auth/too-many-requests': t('sentence.too-many-requests')
      }

      console.log(error.code)

      const defaultMessage = t('sentence.default_error')

      const code = error.code as keyof typeof errorMessages

      console.error('code', error.code)

      const { [code]: errorMessage = defaultMessage } = errorMessages

      return errorMessage
    }

    if (error.code !== 'auth/popup-closed-by-user') {
      Swal.fire({
        backdrop: true,
        title: 'Oops..',
        text: text()
      })
    }
  }

  // const loginWithPassword = async ({ email, password }: { email: string; password: string }) => {
  //   try {
  //     await auth.signInWithEmailAndPassword(email, password)

  //     redirectAfterLogin()
  //   } catch (error) {
  //     // @ts-ignore
  //     alertOnError(error)
  //   }
  // }

  const loginWithLinkToEmail = async ({ email }: { email: string }) => {
    try {
      // Verifica se o email já está cadastrado
      const signInMethods = await auth.fetchSignInMethodsForEmail(email);
  
      if (signInMethods.length === 0) {
        // Se não houver métodos de login associados, o usuário não existe
        throw new FirebaseAuthError("auth/user-not-found", "O usuário não está registrado. Por favor, faça o cadastro primeiro.");
      }
      
      await localforage.setItem('emailForSignIn', email);
      await auth.sendSignInLinkToEmail(email, getActionCodeSettings())

      return Promise.resolve(true)
    } catch (error) {
      // @ts-ignore
      alertOnError(error as LoginError)
      return Promise.resolve(false)
    }
  }

  const handleEmailLink = async () => {
    // Verifica se a URL atual é um link de sign-in válido
    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Recupera o email salvo no localStorage
      let email = await localforage.getItem('emailForSignIn') as string | null;

      console.log(`Login as ${email}`)
      
      // if (!email) {
      //   // Caso o usuário abra em outro navegador/dispositivo
      //   email = window.prompt('Por favor, confirme seu email para completar o login');
      // }

      // Função para validar o formato do email
      const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      // Se o email não estiver salvo, pede ao usuário que digite
      while (!email || !isValidEmail(email)) {
        email = window.prompt('Por favor, confirme seu email para completar o login');

        // Se o usuário não inserir um email válido, o loop continua
        if (!email || !isValidEmail(email)) {
          alert("Por favor, insira um email válido.");
        }
      }
  
      try {
        // Tenta fazer o login com o link
        await auth.signInWithEmailLink(email, window.location.href);
        
        // Limpa o email do localStorage
        await localforage.removeItem('emailForSignIn');
        redirectAfterLogin()
        return;
      } catch (error) {

        console.error('Erro na autenticação:', error);
      }
    }
    alert("URL expirada ou inválida")
    return;
  };

  const loginWithFacebook = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider()

    auth.useDeviceLanguage()

    try {
      await auth.signInWithPopup(facebookProvider)
    } catch (error) {
      // @ts-ignore
      alertOnError(error as LoginError)
    }
  }

  const loginWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()

    auth.useDeviceLanguage()

    try {
      const { credential } = await auth.signInWithPopup(googleProvider)

      if (credential) {
        await auth.signInWithCredential(credential)

        redirectAfterLogin()
      }
    } catch (error) {
      // @ts-ignore
      alertOnError(error as LoginError)
    }
  }

  const sendPasswordResetEmail = async (email: string) => {
    try {
      await auth.sendPasswordResetEmail(email)

      Swal.fire({
        backdrop: true,
        title: t('state.recovery_password.title'),
        text: t('state.recovery_password.message'),
        confirmButtonText: t('state.recovery_password.action_confirm')
      })

      router.push({ name: 'login' })
    } catch (error) {
      // @ts-ignore
      alertOnError(error)
    }
  }

  async function loginWithCustomToken (authToken: string) {
    try {
      await auth.signInWithCustomToken(authToken)

      redirectAfterLogin()
    } catch (error) {
      alertOnError(error as LoginError)
    }
  }

  return {
    isLoggedIn,
    loginWithLinkToEmail,
    handleEmailLink,
    loginWithFacebook,
    loginWithGoogle,
    logout,
    sendPasswordResetEmail,
    loginWithCustomToken
  }
}
