import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { routes } from './routes'
import { titleGuard, authGuard, subscriptionGuard, mauticGuard, luckyOrangeGuard } from './guards'

function wait(duration: number) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

async function tryScrollToAnchor(elementId: string, timeout = 1000, delay = 100) {
  while (timeout > 0) {
    const el = document.getElementById(elementId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
      break;
    }
    await wait(delay);
    timeout = timeout - delay;
  }
}

const createHistory = () => {
  const base = process.env.BASE_URL

  if (process.env.VUE_APP_ROUTER_MODE === 'hash') {
    return createWebHashHistory(base)
  }

  return createWebHistory(base)
}

const router = createRouter({
  history: createHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      // Required because our <RouterView> is wrapped in a <Transition>
      // So elements are mounted after a delay
      tryScrollToAnchor(to.hash.replace('#', ''), 1000, 100);
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes
})

router.beforeEach(titleGuard)
router.beforeEach(authGuard)
router.beforeEach(subscriptionGuard)
router.beforeEach(mauticGuard)
router.beforeEach(luckyOrangeGuard)

export default router
