import { useAxios } from '@/composables/useAxios'
import { auth, firestore } from '@/plugins/firebase'

const state = {
  isLoaded: false,
  isGenerating: false,
  lastGenerationRequest: null,
  automatedContents: []
}

const getters = {
  recent: ({ automatedContents, lastGenerationRequest }: {automatedContents: any[], lastGenerationRequest: Date | null}) => {
    if (!lastGenerationRequest) {
      return []
    }
    return automatedContents.filter(automatedContent => (automatedContent.createdAt >= lastGenerationRequest && !automatedContent.approved))
  },
  available: ({automatedContents}: {automatedContents: any[]}) => {
    return automatedContents.filter(automatedContent => !automatedContent.archived)
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setAutomatedContents (state: any, payload: any[]) {
    state.automatedContents = payload
  },
  setIsGenerating (state: any, payload: boolean) {
    state.isGenerating = payload
    if (payload) {
      state.lastGenerationRequest = new Date()
    }
  }
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    firestore
      .collection('automatedContent')
      .where('lockedFor', '==', profileId)
      .where('requesterRoler', '==', 'customer')
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const automatedContents = querySnapshot.docs
          .map(automatedContent => ({
            id: automatedContent.id,
            ...automatedContent.data(),
            approved: automatedContent.data().approved || false,
            createdAt: automatedContent.data().createdAt.toDate(),
            updatedAt: automatedContent.data().updatedAt.toDate() || automatedContent.data().createdAt.toDate()
          }))
          .filter(automatedContent => !('rejectedBy' in automatedContent))
        commit('setIsGenerating', false)
        commit('setAutomatedContents', automatedContents)
        commit('setLoaded')
      })
  },
  async delete(_context: any, documentId: string) {
    const currentUser = auth.currentUser

    if (currentUser) {
      await firestore
        .collection('automatedContent')
        .doc(documentId)
        .update({
          archived: true,
          updatedAt: new Date()
        })
    }
  },
  async approve(_context: any, documentId: string) {
    await firestore
      .collection('automatedContent')
      .doc(documentId)
      .update({
        approved: true,
        updatedAt: new Date()
      })
  },
  async makeUsed(_context: any, documentId: string) {
    await firestore
      .collection('automatedContent')
      .doc(documentId)
      .update({
        used: true,
        approved: true,
        updatedAt: new Date()
      })
  },
  async generate({ commit }: any, { headline, profileId } : {headline: string, profileId: string}) {
    commit('setIsGenerating', true)
    const { httpFunctions } = await useAxios()
    await httpFunctions.post('/contentGeneration-requests/contents', {
      profileId,
      headline,
      amount: 3
    })
  }
}

export const automatedContent = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}