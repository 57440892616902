import { firestore } from '@/plugins/firebase'

interface ConnectedSocialAccount {
  source?: string
  name: string
  username: string
  image: string
  url: string
}

const state = {
  isLoaded: false,
  ref: null,
  facebook: null,
  instagram: null,
  linkedin: null,
  twitter: null,
  tiktok: null,
}

const getters = {
  connectedFacebook: ({ facebook }: any) => {
    return facebook
  },
  connectedInstagram: ({ instagram }: any) => {
    return instagram
  },
  connectedLinkedin: ({ linkedin }: any) => {
    return linkedin
  },
  connectedTwitter: ({ twitter }: any) => {
    return twitter
  },
  availables: ({facebook, instagram, linkedin, twitter, tiktok, youtube}: any) => {
    return {
      instagram,
      facebook,
      linkedin,
      twitter,
      tiktok,
      youtube
    }
  },
  hasUnsavedData: ({ hasUnsavedData }: any) => {
    return hasUnsavedData
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setRef (state: any, payload: Object) {
    state.ref = payload
  },
  setConnected (state: any, payload: ConnectedSocialAccount) {
    const { source, ...data } = payload
    if (source) {
      state[source] = data
    }
  }
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    const ref = firestore
      .collection('socialAccounts')
      .doc(profileId)

    ref.onSnapshot((docSnapshot) => {
      if (docSnapshot.exists) {
        const socialAccounts = docSnapshot.data()

        if (socialAccounts) {
          const { facebook, instagram, linkedin, twitter, tiktok, youtube } = socialAccounts

          if (facebook && facebook.selectedPage) {
            commit('setConnected', {
              source: 'facebook',
              name: facebook.selectedPage.name || null,
              username: null,
              image: facebook.selectedPage.picture || null,
              url: facebook.selectedPage.link || null
            })
          }

          if (instagram && instagram.selectedPage) {
            commit('setConnected', {
              source: 'instagram',
              name: instagram.selectedPage.name || null,
              username: instagram.selectedPage.username || null,
              image: instagram.selectedPage.profile_picture_url || null,
              url: `https://www.instagram.com/${instagram.selectedPage.username}`
            })
          }

          if (linkedin && linkedin.selectedPage) {
            commit('setConnected', {
              source: 'linkedin',
              name: linkedin.selectedPage.name || null,
              username: null,
              image: linkedin.selectedPage.logo || null,
              url: `https://www.linkedin.com/company/${linkedin.selectedPage}`
            })
          }

          if (twitter) {
            commit('setConnected', {
              source: 'twitter',
              name: twitter.name || null,
              username: twitter.screen_name || null,
              image: twitter.profile_image_url || null,
              url: twitter.link || null
            })
          }

          if (tiktok) {
            commit('setConnected', {
              source: 'tiktok',
              name: tiktok.display_name || null,
              username: tiktok.display_name || null,
              image: tiktok.avatar_url_100 || null,
              url: tiktok.profile_deep_link || null
            })
          }

          if (youtube) {
            commit('setConnected', {
              source: 'youtube',
              name: youtube.name || null,
              username: youtube.name || null,
              image: youtube.pictureStorage || null,
              url: null
            })
          }
        }
        
      }

      commit('setLoaded')
    })

    commit('setRef', ref)
  }
}

export const socialAccounts = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}