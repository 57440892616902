
import { defineComponent, unref, toRefs, computed, ref, watchEffect, onMounted } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import countries from './countries'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    BaseDropdown,
  },

  props: {
    name: String,
    label: String,
    help: String,
    placeholder: {
      type: String,
      default: ' ',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    mask: {
      type: [String, Array],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: String,
    },
    autocomplete: {
      required: false,
      type: String,
      default: '',
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    ref: {
      required: false,
      type: String,
      default: '',
    },
    leftIcon: {
      required: false,
      type: String,
      default: '',
    },
    rightIcon: {
      required: false,
      type: String,
      default: '',
    },
    className: {
      required: false,
      type: String,
      default: '',
    }
  },

  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const { placeholder, leftIcon, rightIcon, type, mask } = toRefs(props)

    const { locale } = useI18n()

    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const countryCode = ref('')
    const currentMask = ref<any>(null)

    const value = computed({
      get: () => type.value === 'tel' ? removeCountryCode(props.modelValue) : props.modelValue,
      set: (value) => emit('update:modelValue', type.value === 'tel' ? mergePhoneValue(value) : value)
    })

    const removeCountryCode = (value: string | number) => {
      const country = countries.find(c => c.code === countryCode.value)
      if (country) {
        const output = value.toString().trim().startsWith(country.value) ? value.toString().trim().replace(country.value, '').trim() : value
        return output
      }
      return value
    }

    const mergePhoneValue = (value: string | number) => {
      const country = countries.find(c => c.code === countryCode.value)
      return `${country?.value || ''} ${value}`.trim()
    }

    const changeCountryCode = (code: string) => {
      countryCode.value = code
      closeDropdown()
    }

    const currentCountry = computed(() => {
      const country = countries.find(c => c.code === countryCode.value)
      return country
    })

    watchEffect(() => {
      const country = countries.find(c => c.code === countryCode.value)
      if (country) {
        currentMask.value = country.masks
      }
    })

    onMounted(() => {
      if (mask.value) {
        currentMask.value = mask.value
      }
      if (type.value === 'tel') {
        const country = countries.find(c => c.value.length >= 2 && props.modelValue.toString().startsWith(c.value))
        if (country) {
          countryCode.value = country.code
        }

        if (!countryCode.value && locale.value === 'pt-br') {
          countryCode.value = "BR"
        }
      }
    })

    const inputClassObject = computed(() => {
      let result: { [key: string]: boolean } = {}
      if ('className' in props) {
        const items = props.className as unknown as string
        items.split(' ').map((item) => {
          const key = item as keyof typeof result
          result[key] = true
        })
      }
      return result
    })

    const hasPlaceholder = computed(() => {
      return !!unref(placeholder).trim().length
    })

    const hasLeftIcon = computed(() => {
      return !!unref(leftIcon).trim().length
    })

    const hasRightIcon = computed(() => {
      return !!unref(rightIcon).trim().length
    })

    const isPasswordType = computed(() => {
      return unref(type) === 'password'
    })

    const hasError = computed(() => {
      return props.errorMessage !== undefined
    })

    const lineClass = computed(() => {
      return hasError.value
        ? 'focus-within:border-red-500'
        : 'focus-within:border-blue-500'
    })

    const {
      isActive: isPasswordVisible,
      toggle: togglePasswordVisibility
    } = useToggle(false)

    const validatedInputType = computed(() => {
      if (unref(isPasswordType)) {
        return unref(isPasswordVisible) ? 'text' : 'password'
      }
      return unref(type)
    })

    return {
      hasError,
      hasLeftIcon,
      hasRightIcon,
      hasPlaceholder,
      isPasswordType,
      isPasswordVisible,
      togglePasswordVisibility,
      validatedInputType,
      value,
      lineClass,
      inputClassObject,
      countries,
      countryCode,
      currentMask,
      changeCountryCode,
      isDropdownOpen,
      currentCountry
    }
  }
})
