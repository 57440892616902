import { ContentPublisherGateway } from '@/services/ContentPublisherGateway'

interface Post {
  id: string
  title: string,
  content: string
  hashtags: string[]
  thumbnail: string
}

interface Schedule {
  id?: string
  isPublished: boolean
  socialNetwork: string
  publication: Post
  user: string
  profile: string,
  scheduledTo: Date,
  socialAccount: object
}

const toCalendarProps = (schedule: Schedule) => {

  const getContent = () => {
    const content = schedule.publication.content ? schedule.publication.content : ''
    const hashtags = schedule.publication.hashtags ? schedule.publication.hashtags.join(' ') : ''

    return content + ' ' + hashtags
  }

  const title = schedule.publication.title || `${schedule.socialNetwork} post`

  return {
    key: schedule.id,
    customData: {
      publicationId: schedule.publication.id,
      id: schedule.id,
      title,
      content: getContent(), 
      thumbnail: schedule.publication.thumbnail,
      socialNetwork: schedule.socialNetwork,
      isPublished: schedule.isPublished,
      scheduledTo: schedule.scheduledTo,
      type: 'social_post'
    },
    dates: schedule.scheduledTo
  }
}

const state = {
  isLoaded: false,
  schedules: []
}

const getters = {
  all: ({ schedules }: { schedules: Schedule[] }) => {
    return schedules.map((schedule: Schedule) => toCalendarProps(schedule))
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setSchedules (state: any, payload: Schedule[]) {
    state.schedules = payload
  },
  remove (state: any, id:string) {
    state.schedules = state.schedules.filter((schedule: Schedule) => schedule.id !== id)
  }
}

const actions = {
  async fetch ({ commit }: any, { profileId, userId }: { profileId: string; userId:string }) {
    const contentPublisherGateway = new ContentPublisherGateway()
    const response = await contentPublisherGateway.list(profileId)

    const schedules = response.map((schedule) => {
      return {
        id: schedule.id,
        isPublished: schedule._isPublished,
        socialNetwork: schedule.source,
        scheduledTo: schedule.publishedAt,
        publication: {
          id: schedule.content.contentId,
          title: schedule.content.title,
          content: schedule.content.content,
          hashtags: schedule.content.hashtags,
          thumbnail: schedule.content.thumbnail
        }
      }
    })

    commit('setSchedules', schedules)
    commit('setLoaded')
  },
  async remove({ commit }: any, { profileId, scheduleId }: { profileId: string; scheduleId:string }) {
    const contentPublisherGateway = new ContentPublisherGateway()
    await contentPublisherGateway.delete(profileId, scheduleId)
    
    commit('remove', scheduleId)
  }
}

export const schedules = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}