<template>
  <div class="flex flex-col w-full">
    <label
      :for="name"
      :class="{'text-red-700': hasError}"
      v-if="label"
    >
      {{ label }}
    </label>
    <div
      class="flex items-center relative"
      :class="lineClass"
    >
      <i
        class="absolute text-gray-700 px-2 "
        :class="leftIcon"
        v-if="hasLeftIcon && type !== 'tel'"
      ></i>

      <base-dropdown show-arrow position="left" v-if="type === 'tel'" v-model:open="isDropdownOpen" class="p-2 w-auto mr-4 border rounded-md focus:outline-none">
        <img :src="currentCountry?.flag" :alt="currentCountry?.name" class="w-6" v-if="currentCountry">
        <template v-slot:options>
          <div class="flex flex-col bg-white p-2 gap-1">
            <div v-for="country in countries" :key="country.code" @click="changeCountryCode(country.code)" class="flex gap-2 items-center cursor-pointer">
              <img :src="country.flag" :alt="country.name" class="h-4">
              {{ country.name }}
            </div>
          </div>
        </template>
      </base-dropdown>

      <input
        class="block w-full"
        :class="{ 'pl-8': hasLeftIcon, 'pr-8': isPasswordType, ...inputClassObject, 'input-error': hasError }"
        :name="name"
        :type="validatedInputType"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :required="required"
        :ref="ref"
        v-maska="currentMask"
        v-model="value"
      />
      <i
        class="absolute right-0 text-gray-700 px-2 "
        :class="rightIcon"
        v-if="hasRightIcon"
      ></i>
      <button
        v-if="isPasswordType"
        @click.prevent="togglePasswordVisibility"
        type="button"
        class="absolute right-0 focus:outline-none"
        tabindex="-1"
      >
          <i v-if="isPasswordVisible" class="far fa-eye text-gray-700 px-2"></i>
          <i v-else class="far fa-eye-slash text-gray-700 px-2"></i>
      </button>
    </div>
    <div v-if="help || hasError">
      <span
        class="text-sm text-red-700 block"
        v-if="hasError"
        v-html="errorMessage">
      </span>
      <span
        class="text-sm text-gray-500 block"
        v-if="help"
        v-html="help">
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, unref, toRefs, computed, ref, watchEffect, onMounted } from 'vue'
import { useToggle } from '@/composables/useToggle'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import countries from './countries'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    BaseDropdown,
  },

  props: {
    name: String,
    label: String,
    help: String,
    placeholder: {
      type: String,
      default: ' ',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    mask: {
      type: [String, Array],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      required: false,
      type: String,
    },
    autocomplete: {
      required: false,
      type: String,
      default: '',
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    ref: {
      required: false,
      type: String,
      default: '',
    },
    leftIcon: {
      required: false,
      type: String,
      default: '',
    },
    rightIcon: {
      required: false,
      type: String,
      default: '',
    },
    className: {
      required: false,
      type: String,
      default: '',
    }
  },

  emits : [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const { placeholder, leftIcon, rightIcon, type, mask } = toRefs(props)

    const { locale } = useI18n()

    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const countryCode = ref('')
    const currentMask = ref<any>(null)

    const value = computed({
      get: () => type.value === 'tel' ? removeCountryCode(props.modelValue) : props.modelValue,
      set: (value) => emit('update:modelValue', type.value === 'tel' ? mergePhoneValue(value) : value)
    })

    const removeCountryCode = (value: string | number) => {
      const country = countries.find(c => c.code === countryCode.value)
      if (country) {
        const output = value.toString().trim().startsWith(country.value) ? value.toString().trim().replace(country.value, '').trim() : value
        return output
      }
      return value
    }

    const mergePhoneValue = (value: string | number) => {
      const country = countries.find(c => c.code === countryCode.value)
      return `${country?.value || ''} ${value}`.trim()
    }

    const changeCountryCode = (code: string) => {
      countryCode.value = code
      closeDropdown()
    }

    const currentCountry = computed(() => {
      const country = countries.find(c => c.code === countryCode.value)
      return country
    })

    watchEffect(() => {
      const country = countries.find(c => c.code === countryCode.value)
      if (country) {
        currentMask.value = country.masks
      }
    })

    onMounted(() => {
      if (mask.value) {
        currentMask.value = mask.value
      }
      if (type.value === 'tel') {
        const country = countries.find(c => c.value.length >= 2 && props.modelValue.toString().startsWith(c.value))
        if (country) {
          countryCode.value = country.code
        }

        if (!countryCode.value && locale.value === 'pt-br') {
          countryCode.value = "BR"
        }
      }
    })

    const inputClassObject = computed(() => {
      let result: { [key: string]: boolean } = {}
      if ('className' in props) {
        const items = props.className as unknown as string
        items.split(' ').map((item) => {
          const key = item as keyof typeof result
          result[key] = true
        })
      }
      return result
    })

    const hasPlaceholder = computed(() => {
      return !!unref(placeholder).trim().length
    })

    const hasLeftIcon = computed(() => {
      return !!unref(leftIcon).trim().length
    })

    const hasRightIcon = computed(() => {
      return !!unref(rightIcon).trim().length
    })

    const isPasswordType = computed(() => {
      return unref(type) === 'password'
    })

    const hasError = computed(() => {
      return props.errorMessage !== undefined
    })

    const lineClass = computed(() => {
      return hasError.value
        ? 'focus-within:border-red-500'
        : 'focus-within:border-blue-500'
    })

    const {
      isActive: isPasswordVisible,
      toggle: togglePasswordVisibility
    } = useToggle(false)

    const validatedInputType = computed(() => {
      if (unref(isPasswordType)) {
        return unref(isPasswordVisible) ? 'text' : 'password'
      }
      return unref(type)
    })

    return {
      hasError,
      hasLeftIcon,
      hasRightIcon,
      hasPlaceholder,
      isPasswordType,
      isPasswordVisible,
      togglePasswordVisibility,
      validatedInputType,
      value,
      lineClass,
      inputClassObject,
      countries,
      countryCode,
      currentMask,
      changeCountryCode,
      isDropdownOpen,
      currentCountry
    }
  }
})
</script>

<style>
  @layer components {
    .input-error {
      @apply border-red-700 !important;
    }

  }
</style>

<style scoped>
  .rounded-r-none {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
</style>
