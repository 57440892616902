import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { auth } from '@/plugins/firebase'
import store from '@/store'

export const subscriptionGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isLoggedIn = auth.currentUser !== null

  if (isLoggedIn) {
    await store.dispatch('profiles/fetch')

    const profileId = store.getters['profiles/current'].id
    await store.dispatch('socialAccounts/fetch', profileId)

    const activeSubscriptionRequired = true

    if (activeSubscriptionRequired) {
      //
    }
  }

  return next()
}
