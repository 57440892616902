
import { defineComponent, onBeforeMount } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    LanguageSelect
  },
  setup () {
    const router = useRouter()

    const {
      isLoggedIn,
      handleEmailLink
    } = useAuth()
    
    onBeforeMount(async () => {
      if (isLoggedIn.value) {
        console.log('isLoggedIn')
        router.replace({ name: 'change-profile' })
      } else {
        console.log('isNotLoggedIn')
        await handleEmailLink()
      }
    })

    return {
    }
  }
})
