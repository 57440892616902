import { auth, firestore } from '@/plugins/firebase'

type PriorityRole = 'very-low' | 'low' | 'normal' | 'high'

type StatusRole = 'pending' | 'read' | 'deleted'
interface NotificationInput {
  createdAt: Date
  data: {
    [key:string]: any,
  }
  notification: {
    body: string
    title: string
  }
  priority: PriorityRole
  profileId: string
  status: StatusRole
  userId: string
}

interface NotificationOutput {
  id: string
  createdAt: Date
  body: string
  title: string
  priority: PriorityRole
  status: StatusRole
  data?: {
    context: string
    [key:string]: any
  }
}

interface IState {
  notifications: NotificationOutput[]
}

const state: IState = {
  notifications: [],
}

const getters = {
  available: ({notifications}: IState) => {
    return notifications
      .reduce((prev, curr: NotificationOutput) => {
        if (curr.status === 'pending') {
          const hasPriority = prev.some((n: NotificationOutput) => n.priority === 'high')
          prev.push({
            ...curr,
            openImmediately: hasPriority ? false : curr.priority === 'high'
          })
        }
        return prev
      }, [] as any)
  }
}

const mutations = {
  setNotifications (state: IState, payload: NotificationOutput[]) {
    state.notifications = payload
  }
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    const currentUser = auth.currentUser

    firestore
      .collection('notifications')
      .where('userId', '==', currentUser?.uid)
      .where('profileId', '==', profileId)
      .onSnapshot((notificationsQuery) => {
        const notifications = notificationsQuery.docs
          .map((doc) => {
            const data = doc.data()
            return {
              id: doc.id,
              createdAt: data.createdAt.toDate(),
              body: data.notification.body,
              title: data.notification.title,
              priority: data.priority,
              status: data.status,
              ...(data.data && {data: data.data}),
            }
          })
          .sort((a, b) => b.createdAt - a.createdAt) as NotificationOutput[]

          commit('setNotifications', notifications)
      })
  }
}

export const notifications = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}