import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const titleGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string
  }

  return next()
}
