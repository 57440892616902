<template>
  <div class="w-full sm:w-4/5 md:3/4 2xl:w-1/2 pb-8">
    <div class="hidden md:block text-center my-10">
      <h1>{{ $t('page.login.sentence.welcome') }}</h1>
    </div>
    <div class="flex items-center justify-center w-full">
      <i class="fas fa-spin fa-spinner fa-2x"></i>
    </div>
  </div>
  <div class="absolute top-0 right-0 m-3">
    <language-select clean />
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'
import LanguageSelect from '@/components/settings/languageSelect.vue'
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    LanguageSelect
  },
  setup () {
    const router = useRouter()

    const {
      isLoggedIn,
      handleEmailLink
    } = useAuth()
    
    onBeforeMount(async () => {
      if (isLoggedIn.value) {
        console.log('isLoggedIn')
        router.replace({ name: 'change-profile' })
      } else {
        console.log('isNotLoggedIn')
        await handleEmailLink()
      }
    })

    return {
    }
  }
})
</script>
