
import { defineComponent } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'

export default defineComponent({
  render () {
    return undefined
  },
  setup () {
    const { logout } = useAuth()

    logout()
  }
})
