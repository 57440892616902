import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const mauticGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const scriptId = 'mautic-tracking-script';

  if (to.matched.some(record => record.meta.useMautic)) {
    // Verifica se o script já foi adicionado ao body para evitar duplicatas
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.innerHTML = `
        (function(w,d,t,u,n,a,m){
          w['MauticTrackingObject']=n;w[n]=w[n]||function(){
              (w[n].q=w[n].q||[]).push(arguments)
          },a=d.createElement(t),m=d.getElementsByTagName(t)[0];
          a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://mautic.manycontent.com/mtc.js','mt');
        
        if (!window.mtEventSent) {
          mt('send', 'pageview'); // Rastreamento de visualização de página
          mt('send', 'event', 'Visualização de Produto', 'visit', 'ID do Produto', 1);
          
          window.mtEventSent = true; // Impede envio duplicado
        }
        
        function trackConversion(value, currency, transactionId) {
          mt('send', 'conversion', {
            'valor': value,
            'moeda': currency,
            'id_transação': transactionId
          });
        }
      `;
      document.body.appendChild(script);
    }
  } else {
    // Opcional: Remova o script se a página não requer mais o rastreamento
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      document.body.removeChild(existingScript);
    }
  }
  next();
}
