import { admin, firestore } from '@/plugins/firebase'

const getToolsRef = (profileId: string) => {
  return firestore
    .collection('tools')
    .doc(profileId)
}

const state = {
  isLoaded: false,
  titles: [],
  whatsappLinks: [],
  personas: []
}

const getters = {
  titles: ({ titles }: {titles: { sentence: string, keyword: string }[]}) => {
    return titles
      .filter((title: { sentence: string, keyword: string }) => !!title.sentence)
      .map((title: { sentence: string, keyword: string }) => {
        try {
          return title.sentence.replaceAll(title.keyword, `<strong>${title.keyword}</strong>`)
        } catch (error) {
          return null
        }
      })
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setTitles (state: any, payload: any[]) {
    state.titles = payload
  },
  setWhatsappLinks (state: any, payload: any[]) {
    state.whatsappLinks = payload
  },
  setPersonas (state: any, payload: any[]) {
    console.log(payload)
    state.personas = payload
  },
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    const toolsRef = getToolsRef(profileId)
    const docSnapshot = await toolsRef.get()

    if (!docSnapshot.exists) {
      await toolsRef.set({
        titles: [],
        personas: [],
        whatsappLinks: []
      })
    } else {
      const documentData = docSnapshot.data()
      if (documentData && 'titles' in documentData) {
        commit('setTitles', documentData.titles)
      } else {
        await toolsRef.set({
          titles: []
        }, { merge: true })
      }

      if (documentData && 'whatsappLinks' in documentData) {
        commit('setWhatsappLinks', documentData.whatsappLinks)
      } else {
        await toolsRef.set({
          whatsappLinks: []
        }, { merge: true })
      }

      if (documentData && 'personas' in documentData) {
        commit('setPersonas', documentData.personas)
      } else {
        await toolsRef.set({
          personas: []
        }, { merge: true })
      }
    }

    commit('setLoaded')
  },
  async addTitle ({ commit, state }: any, { profileId, title }: { profileId: string; title: string }) {
    const getTitle = (title: string) => title.replace(/(<([^>]+)>)/gi, "")
    
    const getKeyword = (title: string) => {
      if (title) {
        const keyword = title.match(/<strong>(.*?)<\/strong>/g) || []

        if (keyword) {
          const retult = keyword.map(val => val.replace(/<\/?strong>/g,''))
          return retult.length ? retult[0] : ''
        }
      }
      return ''
    }

    const data = {
      sentence: getTitle(title),
      keyword: getKeyword(title)
    }
    
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      titles: admin.firestore.FieldValue.arrayUnion(data)
    })

    const titles = [...state.titles, data]

    commit('setTitles', titles)
  },
  async removeTitle({ commit, state }: any, { profileId, titleIndex }: { profileId: string; titleIndex: number }) {
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      titles: admin.firestore.FieldValue.arrayRemove(state.titles[titleIndex])
    })

    const currentTitles = state.titles
    currentTitles.splice(titleIndex, 1)

    commit('setTitles', currentTitles)
  },
  async addWhatsappLink ({ commit, state }: any, { profileId, phone, message }: { profileId: string; phone: string; message: string }) {
    const getWhatsappLink = () => {
      const whatsappurl = `https://wa.me/${phone.replace(/\D/g, '')}`
      return message
        ? `${whatsappurl}?text=${encodeURI(message)}`
        : whatsappurl
    }

    const data = {
      phone,
      message,
      link: getWhatsappLink()
    }
    
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      whatsappLinks: admin.firestore.FieldValue.arrayUnion(data)
    })

    const whatsappLinks = [...state.whatsappLinks, data]

    commit('setWhatsappLinks', whatsappLinks)
  },
  async removeWhatsappLink({ commit, state }: any, { profileId, whatsappLinkIndex }: { profileId: string; whatsappLinkIndex: number }) {
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      whatsappLinks: admin.firestore.FieldValue.arrayRemove(state.whatsappLinks[whatsappLinkIndex])
    })

    const currentWhatsappLink = state.whatsappLinks
    currentWhatsappLink.splice(whatsappLinkIndex, 1)

    commit('setWhatsappLinks', currentWhatsappLink)
  },
  async addPersona ({ commit, state }: any, { profileId, persona }: { profileId: string; persona: { avatar: string, responses: [] } }) { 
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      personas: admin.firestore.FieldValue.arrayUnion(persona)
    })

    const personas = [...state.personas, persona]

    commit('setPersonas', personas)
  },
  async removePersona({ commit, state }: any, { profileId, personaIndex }: { profileId: string; personaIndex: number }) {
    const toolsRef = getToolsRef(profileId)

    await toolsRef.update({
      personas: admin.firestore.FieldValue.arrayRemove(state.personas[personaIndex])
    })

    const currentPersonas = state.personas
    currentPersonas.splice(personaIndex, 1)

    commit('setPersonas', currentPersonas)
  },
}

export const tools = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}