import { useAxios } from '@/composables/useAxios'
import { firestore } from '@/plugins/firebase'

const getNewPost = (id: string, profileId: string, userId: string, props: object | undefined) => {
  const post = {
    profile: profileId,
    user: userId,
    content: '',
    createdBy: 'Customer',
    creative: null,
    freeContent: false,
    fullPath: null,
    hashtags: [],
    publicationUID: null,
    published: false,
    publishedOn: { facebook: null, instagram: null, linkedin: null, twitter: null },
    socialAccounts: { facebook: false, instagram: false, linkedin: false, twitter: false },
    thumbnail: null,
    title: '',
    uid: id,
    version: 1,
    createdDate: new Date(),
    type: 'image'
  }
  
  return {
    ...post,
    ...(props && { ...props })
  }
}

const state = {
  ref: null,
  post: {},
  hasUnsavedData: false,
  isLoadingCreative: false,
  myPosts: []
}

const getters = {
  current: ({ post }: any) => {
    return post
  },
  hasUnsavedData: ({ hasUnsavedData }: any) => {
    return hasUnsavedData
  },
  myPosts: ({ myPosts }: any) => {
    return myPosts
  },
  preview: ({ myPosts }: any) => {
    return myPosts.slice(0, 10)
  }
}

const mutations = {
  setRef (state: any, payload: Object) {
    state.ref = payload
  },
  setCurrent (state: any, payload: Object) {
    state.post = payload
  },
  update (state: any, payload: Object) {
    state.post = payload
    state.hasUnsavedData = true
  },
  clearStore (state: any) {
    state.ref = null
    state.post = {}
    state.hasUnsavedData = false
  },
  setHasUnsavedData (state: any, payload: Boolean) {
    state.hasUnsavedData = payload
  },
  setIsLoadingCreative (state: any, payload: Boolean) {
    state.isLoadingCreative = payload
  },
  setMyPosts (state: any, payload: Object[]) {
    state.myPosts = payload
  },
  updateMyPosts (state: any, updatedPost: {uid: string}) {
    state.myPosts = state.myPosts.map((post: {uid: string}) => {
      return post.uid === updatedPost.uid ? updatedPost : post
    })
  },
  removeFromMyPosts (state: any, postId: string) {
    state.myPosts = state.myPosts.reduce((prev: any[], curr: {uid: string}) => {
      if (curr.uid !== postId) {
        prev.push(curr)
      }
      return prev
    }, [])
  },
  clearMyPosts (state: any) {
    state.myPosts = []
  },
}

const actions = {
  create ({ commit }: any, payload: { profile: string, user: string, props: object | undefined }) {
    const ref = firestore
      .collection('customerPublications')
      .doc()
      
    commit('setRef', ref)

    commit('setCurrent', getNewPost(ref.id, payload.profile, payload.user, payload.props))
    commit('setHasUnsavedData', true)
  },
  async fetch ({ commit }: any, postId: string) {
    const ref = firestore
      .collection('customerPublications')
      .doc(postId)
    
    const snapshot = await ref.get()

    if (snapshot.exists) {
      commit('setRef', ref)

      commit('setCurrent', {
        uid: snapshot.id,
        ...snapshot.data()
      })

      commit('setHasUnsavedData', false)
    }
  },
  async fetchMyPosts ({ commit }: any, profileId: string) {
    const publicationsQuery = await firestore
      .collection('customerPublications')
      .where('published', '==', false)
      .where('profile', '==', profileId)
      .orderBy('createdDate', 'desc')
      .get()

    const posts = publicationsQuery.docs.map((publicationSnap) => {
      return {
        uid: publicationSnap.id,
      ...publicationSnap.data()
      }
    })

    commit('setMyPosts', posts)
  },
  async save ({ state, commit }: any) {
    await state.ref.set(state.post, { merge: true })
    state.hasUnsavedData = false

    commit('updateMyPosts', state.post)
  },
  async remove ({ state, commit }: any) {
    const { httpFunctions } = await useAxios()
    await httpFunctions.delete(`/content-requests/${state.ref.id}`)
    commit('removeFromMyPosts', state.ref.id)
    commit('clearStore')
  }
}

export const post = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
