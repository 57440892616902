import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const luckyOrangeGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const scriptId = 'luckyorange';

  if (to.matched.some(record => record.meta.useLuckyOrange)) {
    // Verifica se o script já foi adicionado ao body para evitar duplicatas
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      const src = "https://tools.luckyorange.com/core/lo.js?site-id=bdd283ed"
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.setAttribute('src', src)
      document.body.appendChild(script);
    }
  } else {
    // Opcional: Remova o script se a página não requer mais o rastreamento
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      document.body.removeChild(existingScript);
    }
  }
  next();
}
