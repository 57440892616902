<template>
  <div
    ref="dropdownElement"
    class="relative"
  >
    <button
      class="flex focus:outline-none items-center"
      @click.prevent="toggle"
    >
      <slot/>
      <div class="ml-2" v-if="showArrow">
        <i v-if="isOpen" class="fas fa-chevron-up text-gray-400 text-sm"></i>
        <i v-else class="fas fa-chevron-down text-gray-400 text-sm"></i>
      </div>
    </button>

    <transition name="fade">
      <div
        class="card origin-top absolute py-2 w-max space-y-2 ring-gray-200 ring-1 z-10"
        :class="style"
        v-show="isOpen"
      >
        <slot name="options" />
      </div>
    </transition>

  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, unref, onMounted, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    showArrow: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'right'
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:open'
  ],
  setup (props, { emit }) {
    const { position } = toRefs(props)
    const dropdownElement = ref<HTMLElement|null>(null)

    const {
      isActive: isOpen,
      toggle,
      setInactive: closeDropdown
    } = useToggle(props.open)

    watch(() => props.open, (state) => {
      isOpen.value = state
    })

    watch(isOpen, (state) => {
      emit('update:open', state)
    })

    onMounted(() => {
      onClickOutside(dropdownElement, (event) => {
        if (dropdownElement.value) {
          const target = event.target as HTMLElement

          if (isOpen.value === true && !dropdownElement.value.contains(target)) {
            closeDropdown()
          }
        }
      })
    })

    const style = computed(() => {
      switch (unref(position)) {
        case 'rigtht':
          return ['right-0']
        case 'left':
          return ['left-0']
        case 'center':
          return ['left-1/2']
        default:
          return ['right-0']
      }
    })

    return {
      isOpen,
      toggle,
      style,
      dropdownElement
    }
  }
})
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0
  }
</style>
