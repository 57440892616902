import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { auth, functionsEndpoint } from '@/plugins/firebase'

interface IAxiosParams {
  config?: AxiosRequestConfig & { useCache?: boolean };
}

interface CacheEntry {
  expiry: number;
  data: AxiosResponse;
}

const CACHE_DURATION = 5 * 60 * 1000;
const cache: Record<string, CacheEntry> = {};

export const useAxios = async ({ config }: IAxiosParams = {}) => {
  const baseURL = config?.baseURL || functionsEndpoint
  const useCache = config?.useCache ?? false;

  let requestConfig: AxiosRequestConfig = {
    ...config,
    baseURL
  }

  const currentUser = auth.currentUser

  if (currentUser !== null) {
    const token = await currentUser.getIdToken()

    const Authorization = `Bearer ${token}`

    if (!requestConfig.headers) {
      requestConfig.headers = { Authorization }
    } else {
      requestConfig.headers['Authorization'] = Authorization
    }
  }

  const httpFunctions = axios.create(requestConfig)

  // Interceptor de requisição para implementar o cache
  httpFunctions.interceptors.request.use(async (req) => {
    if (!useCache) {
      return req; // Se o cache não é para ser usado, prossegue com a requisição normalmente
    }
    const cacheKey = `${req.method}-${req.url}-${JSON.stringify(req.params)}-${JSON.stringify(req.data)}`;
    const now = Date.now();
    const cacheEntry = cache[cacheKey];
    if (cacheEntry && cacheEntry.expiry > now) {
      req.adapter = () => Promise.resolve(cacheEntry.data); // Retorna os dados em cache como uma resposta
    }
    return req;
  });

  // Interceptor de resposta para armazenar dados no cache
  httpFunctions.interceptors.response.use((response) => {
    if (useCache) { // Armazena no cache somente se useCache estiver habilitado
      const cacheKey = `${response.config.method}-${response.config.url}-${JSON.stringify(response.config.params)}-${JSON.stringify(response.config.data)}`;
      const now = Date.now();
      cache[cacheKey] = {
        expiry: now + CACHE_DURATION,
        data: response,
      };
    }
    return response;
  }, (error) => {
    console.log({...error});
    return Promise.reject(error);
  });

  return {
    httpFunctions
  }
}
