import { deepMergeObject } from '@/composables/utils/deepMergeObject'
import { auth, firestore } from '@/plugins/firebase'

const state = {
  user: {},
  isLoaded: false
}

const getters = {
  user: ({ user }: any) => {
    return user
  }
}

const mutations = {
  setUser (state: any, payload: Object) {
    state.user = payload
  },
  setIsLoaded (state: any, payload: boolean) {
    state.isLoaded = payload
  }
}

const actions = {
  async fetch ({ commit }: any) {
    const currentUser = auth.currentUser
    
    if (currentUser) {
      const userSnapshot = await firestore
        .collection('users')
        .doc(currentUser.uid)
        .get()

      if (userSnapshot.exists) {
        commit('setUser', {
          id: userSnapshot.id,
          ...userSnapshot.data()
        })
        commit('setIsLoaded', true)
      }
    }
  },
  async update ({ state, commit }: any, payload: any = {}) {
    const currentUser = auth.currentUser

    const newData = deepMergeObject(state.user, payload)

    if (currentUser) {
      await firestore
        .collection('users')
        .doc(currentUser.uid)
        .set({ ...newData }, { merge: true })

      commit('setUser', newData)
    }
  }
}

export const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}