<template>
  <select
    :class="{ 'clean': clean }"
    v-model="selectedLanguage"
  >
    <option
      v-for="language in availableLanguages"
      :key="language.caption"
      :value="language.value">
      {{ language.caption }}
    </option>
  </select>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import localforage from 'localforage'
import { useI18n } from 'vue-i18n'
import { availableLanguages as languages } from '@/plugins/i18n'

export default defineComponent({
  props: {
    clean: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const { locale } = useI18n()
    const selectedLanguage = ref(locale)

    watch(selectedLanguage, async (newValue) => {
      locale.value = newValue
      await localforage.setItem('language', newValue)
    })

    const availableLanguages = computed(() => {
      return languages.filter((language) => language.context.includes('app'))
    })

    return {
      locale,
      selectedLanguage,
      availableLanguages
    }
  }
})
</script>

<style scoped>
@layer components {
  .clean {
    @apply bg-white border-none shadow-none focus:ring-0 focus:bg-white text-sm lg:text-base;
  }
}
</style>
