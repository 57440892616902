import axios from 'axios'
import crello from '@/plugins/crello'

const ENDPOINT = crello.getApiUrl()
const apiKey = crello.getApiKey()
const cdnUrl = crello.getCdnUrl()

const getMediaType = (template: {previewScreenVideoUrl?: string, previewImageUrls: string[]}) => {
  if ('previewScreenVideoUrl' in template) {
    return 'video'
  } else if (template.previewImageUrls.length > 1) {
    return 'carousel'
  } else {
    return 'image'
  }
}

const fetchTemplates = async (filters?: {}) => {
  try {
    const params = {
      apiKey: apiKey,
      ...filters
    }

    const { items, count } = (await axios.get<{items: any[], count: number}>(ENDPOINT, { params })).data
    const templates = items
      .map(template => {
        const previewImageUrls = []
        let previewScreenVideoUrl = null

        if ('previewImageUrls' in template) {
          const previews = template.previewImageUrls.map((preview: string) => `${cdnUrl}${preview}`)
          previewImageUrls.push(...previews)
        }

        if ('previewScreenVideoUrl' in template) {
          previewScreenVideoUrl = `${cdnUrl}${template.previewScreenVideoUrl}`
        }

        const thumbnail = previewImageUrls[0]

        return {
          ...template,
          thumbnail,
          mediaType: getMediaType({ ...(previewScreenVideoUrl && {previewScreenVideoUrl}), previewImageUrls }),
          formatKey: template.formatKey,
          ...(previewImageUrls.length && { previewImageUrls }),
          ...(previewScreenVideoUrl && { previewScreenVideoUrl })
        }
      })
      .filter(template => template.mediaType === 'image' && template.formatKey === 'instagramSM')

    return {
      templates,
      count
    }
  } catch (error) {
    console.log(error)
    return {
      count: 0,
      templates: []
    }
  }
}

export default {
  fetchTemplates
}