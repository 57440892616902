import { createStore } from 'vuex'
import { dashboard } from './modules/dashboard'
import { profiles } from './modules/profiles'
import { user } from './modules/user'
import { analytics } from './modules/analytics'
import { post } from './modules/post'
import { socialAccounts } from './modules/socialAccounts'
import { schedules } from './modules/schedules'
import { tools } from './modules/tools'
import { courses } from './modules/courses'
import { templates } from './modules/templates'
import { checkout } from './modules/checkout'
import { ideas } from './modules/ideas'
import { automatedContent } from './modules/automatedContent'
import { contentPack } from './modules/contentPack'
import { notifications } from './modules/notifications'
import { talks } from './modules/talks'

export default createStore({
  modules: {
    dashboard,
    profiles,
    user,
    analytics,
    post,
    socialAccounts,
    schedules,
    tools,
    courses,
    templates,
    checkout,
    ideas,
    automatedContent,
    contentPack,
    notifications,
    talks
  }
})
