<template>
  <component :is="screenComponent">
    <router-view key="main-view"/>
  </component>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, computed, unref } from 'vue'
import { useAuth } from '@/composables/firebase/useAuth'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const { isLoggedIn } = useAuth()
    const router = useRouter()

    const screenComponent = computed(() => {
      if (router.currentRoute.value.path.includes('/pages/')) {
        return defineAsyncComponent(() => import('./components/pages/PagesScreen.vue'))
      } else if (unref(isLoggedIn)) {
        return defineAsyncComponent(() => import('./components/dashboard/DashboardScreen.vue'))
      } else {
        return defineAsyncComponent(() => import('./components/auth/AuthScreen.vue'))
      }

    })

    return {
      isLoggedIn,
      screenComponent
    }
  }
})
</script>

<style src="@/assets/css/style.css"/>
