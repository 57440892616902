
import { computed, defineComponent, ref, toRefs, unref, onMounted, watch } from 'vue'
import { useToggle } from '@/composables/useToggle'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  props: {
    showArrow: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'right'
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:open'
  ],
  setup (props, { emit }) {
    const { position } = toRefs(props)
    const dropdownElement = ref<HTMLElement|null>(null)

    const {
      isActive: isOpen,
      toggle,
      setInactive: closeDropdown
    } = useToggle(props.open)

    watch(() => props.open, (state) => {
      isOpen.value = state
    })

    watch(isOpen, (state) => {
      emit('update:open', state)
    })

    onMounted(() => {
      onClickOutside(dropdownElement, (event) => {
        if (dropdownElement.value) {
          const target = event.target as HTMLElement

          if (isOpen.value === true && !dropdownElement.value.contains(target)) {
            closeDropdown()
          }
        }
      })
    })

    const style = computed(() => {
      switch (unref(position)) {
        case 'rigtht':
          return ['right-0']
        case 'left':
          return ['left-0']
        case 'center':
          return ['left-1/2']
        default:
          return ['right-0']
      }
    })

    return {
      isOpen,
      toggle,
      style,
      dropdownElement
    }
  }
})
