import { useAxios } from '@/composables/useAxios'
import { firestore } from '@/plugins/firebase'

const fetchCourses = async () => {
  try {
    const query = await firestore
      .collection('playlists')
      .get()

    return query.docs
      .map((categorySnap) => {
        const { id, name, order } = categorySnap.data()

        return { id, name, order }
      })
      .sort((a, b) => a.order - b.order)

  } catch (error) {
    console.log(error)
    return []
  }
}

const fetchClasses = async (courseId: string) => {
  const { httpFunctions } = await useAxios()

  try {
    const response = await httpFunctions.get(`/youtube-requests/playlistItems/${courseId}`)
    return response.data
  } catch (error) {
    console.log(error)
    return []
  }
}

export default {
  fetchCourses,
  fetchClasses
}
