import {
  captureException,
  configureScope,
  init,
  setTag,
  setUser as setSentryUser
} from '@sentry/browser'
import { ComponentPublicInstance } from '@vue/runtime-core'
import { version } from '../../package.json'

export const sentry = () => {
  const initSentry = () => init({
    dsn: 'https://a76e0df8dbe94b4cb1182fc0a75b8e35@o179494.ingest.sentry.io/5711502',
    release: version
  })

  const errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
    setTag('info', info)
    captureException(err)
  }

  const resetUser = () => {
    configureScope((scope) => scope.setUser(null))
  }

  const setUser = (userId: string) => {
    setSentryUser({ id: userId })
  }

  return {
    initSentry,
    errorHandler,
    resetUser,
    setUser
  }
}
