const crello = {
  apikey: process.env.VUE_APP_CRELLO_API_KEY
}

const getApiUrl = () => {
  return 'https://create.vista.com/api/partner-api/templates'
}

const getScriptUrl = () => {
  return 'https://create.vista.com/js/frame_v2.min.js'
}

const getCdnUrl = () => {
  return 'https://cdn.create.vista.com'
}

const getApiKey = () => {
  return crello.apikey
}

export default { getScriptUrl, getApiUrl, getCdnUrl, getApiKey }