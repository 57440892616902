import { ref } from 'vue'

export const useToggle = (initialStatus: boolean = true) => {
  const isActive = ref<boolean>(initialStatus)

  const toggle = () => {
    isActive.value = !isActive.value
  }

  const setActive = () => {
    isActive.value = true
  }

  const setInactive = () => {
    isActive.value = false
  }

  return {
    isActive,
    toggle,
    setActive,
    setInactive,
  }
}
