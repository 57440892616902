import { auth, firestore } from '@/plugins/firebase'
// import { ManybotChatGateway } from '@/services/ManybotChatGateway'

interface Talk {
  id: string
}

interface IState {
  talks: Talk[]
}

const state: IState = {
  talks: [],
}

const getters = {
  available: ({talks}: IState) => {
    return talks
  }
}

const mutations = {
  setTalks (state: IState, payload: Talk[]) {
    state.talks = payload
  }
}

const actions = {
  async fetch ({ commit }: any, profileId: string) {
    const currentUser = auth.currentUser

    firestore
      .collection('talks')
      .where('user', '==', currentUser?.uid)
      .where('profile', '==', profileId)
      .onSnapshot((talksSnapshot) => {
        const notifications = talksSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate()
          }))
          .sort((a, b) => b.createdAt - a.createdAt) as Talk[]

          commit('setTalks', notifications)
      })
  },
  // async sendMessage({commit}: any, {message, profileId}: {message: string, profileId: string}) {
  //   const currentUser = auth.currentUser

  //   const manybotChatGateway = new ManybotChatGateway()

  //   const response = await manybotChatGateway.sendMessage({
  //     profileId,
  //     userId: currentUser?.uid,
  //     message,
  //   })


  //   firestore
  //     .collection('talks')
      
  // }
}

export const talks = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}