// https://developers.facebook.com/ads/blog/post/v2/2017/11/28/event-tracking-with-multiple-pixels-tracksingle/
declare global {
  interface Window {
    fbq: any;
  }
}

const fbqEnabled = () => {
  return typeof window.fbq !== 'undefined'
}

export const fbPixel = (...args: any[]) => {
  if (!fbqEnabled()) {
    return
  }

  window.fbq(...args)
}

export const initFacebookPixel = (appId: string[], data = {}) => {
  if (!fbqEnabled()) {
    return
  }

  appId.forEach(id => fbPixel('init', id, data));
}
