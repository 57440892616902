const dashboardState = {
  isSidebarVisible: true,
  contentFullWrapper: false
}

const mutations = {
  setSidebarVisibility (state: typeof dashboardState, visibility = false) {
    state.isSidebarVisible = visibility
  },
  setIsFullWrapper (state: typeof dashboardState, isFullWrapper = true) {
    state.contentFullWrapper = isFullWrapper
  }
}

export const dashboard = {
  namespaced: true,
  state: dashboardState,
  mutations
}
