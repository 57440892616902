import { auth, firestore } from '@/plugins/firebase'
import coursesService from '@/services/coursesService'

interface Course {
  id: string
  classes: any[]
}

interface Video {
  id: string
  title: string
  description: string
  thumbnail: string,
  watched: boolean,
  favorite: boolean
}

interface Interaction {
  id: string,
  source: string,
  watched: boolean,
  favorite: boolean
}

const simplify = (youtubeVideo: any, interactions: Interaction[]) : Video => {
  const interaction = interactions.find((interaction: Interaction) => interaction.id === youtubeVideo.snippet.resourceId.videoId)

  return {
    id: youtubeVideo.snippet.resourceId.videoId,
    title: youtubeVideo.snippet.title,
    description: youtubeVideo.snippet.description,
    thumbnail: youtubeVideo.snippet.thumbnails.medium.url,
    watched: interaction ? interaction.watched : false,
    favorite: interaction ? interaction.favorite : false
  }
}

const state = {
  isLoaded: false,
  courses: [] as Course[],
  userInteraction: []
}

const getters = {
  courses: ({ courses }: { courses : Course[] }) : Course[] => {
    return courses
  }
}

const mutations = {
  setLoaded (state: any) {
    state.isLoaded = true
  },
  setCourses (state: any, payload: any[]) {
    state.courses = payload.map(course => ({
      id: course.id,
      name: course.name,
      classes: []
    }))
  },
  setClasses ({ courses, userInteraction }: any, payload: { courseId: string, classes: [] }) {
    courses.forEach((course: Course) => {
      if (course.id === payload.courseId) {
        course.classes = payload.classes
          .map((video) => (simplify(video, userInteraction)))
      }
    })
  },
  setUserInteraction (state: any, payload: Interaction[]) {
    state.userInteraction = payload

    payload.map(interaction => {
      state.courses.forEach((course: Course) => {
        course.classes.forEach((video: Video) => {
          if (video.id === interaction.id) {
            video.watched = interaction.watched
            video.favorite = interaction.favorite
          }
        })
      })
    })

  }
}

const actions = {
  async fetchCourses ({ commit }: any) {
    const courses = await coursesService.fetchCourses()
    commit('setCourses', courses)
    commit('setLoaded')
  },
  async fetchClasses ({ commit }: any, courseId: string) {
    const classes = await coursesService.fetchClasses(courseId)
    if ('items' in classes && classes.items.length) {
      commit('setClasses', { courseId, classes: classes.items })
    }
  },
  async fetchUserInteraction ({ commit }: any) {
    const currentUser = auth.currentUser
    
    if (currentUser) {
      const tutorialsQuery = await firestore
        .collection('users')
        .doc(currentUser.uid)
        .collection('tutorials')
        .get()

      if (!tutorialsQuery.empty) {
        const interactions = tutorialsQuery.docs.map(tutorial => ({
          id: tutorial.id,
          ...tutorial.data()
        }))

        commit('setUserInteraction', interactions)
      }
    }
  },
  async changeWatched ({ state, commit }: any, videoId: string) {
    const currentUser = auth.currentUser

    if (currentUser) {
      const oldInteraction = state.userInteraction.find((interaction: Interaction) => interaction.id === videoId)

      const interaction = {
        ...oldInteraction,
        id: videoId,
        source: 'youtube',
        watched: oldInteraction ? !oldInteraction.watched : true,
        favorite: false,
      }

      await firestore
        .collection('users')
        .doc(currentUser.uid)
        .collection('tutorials')
        .doc(videoId)
        .set(interaction, { merge: true })

      const currentIteraction = [
        ...state.userInteraction.filter((interaction: Interaction) => interaction.id !== videoId),
        interaction
      ]

      commit('setUserInteraction', currentIteraction)
    }
  }
}

export const courses = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}